<template>
  <div class="container">
    <PublicHeader :totalName="totalName" />
    <div class="content">
      <van-field
        v-model="oldPassword"
        :label="$t('editPassword').jmm"
        type="password"
        :placeholder="$t('editPassword').placeholder"
      />
      <van-field
        v-model="Password"
        type="password"
        :label="$t('editPassword').xmm"
        :placeholder="$t('editPassword').placeholder1"
      />
      <van-field
        v-model="submitPasswork"
        :label="$t('editPassword').qrmm"
        type="password"
        :placeholder="$t('editPassword').placeholder2"
      />
    </div>

    <van-button size="large" type="info" color="#8e1473" round @click="handlePassword">{{
      $t('editPassword').qrxf
    }}</van-button>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      totalName: this.$t('editPassword').title,
      Password: '',
      oldPassword: '',
      submitPasswork: ''
    }
  },
  methods: {
    onConfirm(value) {
      this.bankName = value
      this.show = false
    },
    checkPasswork() {
      if (this.oldPassword.length === '' || this.oldPassword.length < 6) {
        this.$toast({
          message: '请输入至少6位的旧密码',
          position: 'bottom'
        })
        return false
      }

      if (this.Password.length === '' || this.Password.length < 6) {
        this.$toast({
          message: '请输入至少6位的新密码',
          position: 'bottom'
        })
        return false
      }
      if (this.submitPasswork.length === '' || this.submitPasswork.length < 6) {
        this.$toast({
          message: '请输入正确的确认密码',
          position: 'bottom'
        })
        return false
      }
      if (this.Password !== this.submitPasswork) {
        this.$toast({
          message: '两次输入的新密码不一致',
          position: 'bottom'
        })
        return false
      }
      return true
    },
    async handlePassword() {
      if (!this.checkPasswork()) {
        return
      }
      await userApi.editPassword({
        newPassword: this.Password,
        oldPassword: this.oldPassword
      })
      this.$toast.success('修改密码成功')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  font-size: 14px
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  .content
    padding 10px
    margin 10px
    border-radius 4px
    background #17181e
  .van-field
    background none
    margin 0
    >>> .van-field__label
      color #fff
      margin 0
    >>> .van-field__control
      color #fff
    >>> input::-webkit-input-placeholder
      color #969799
  .van-button
    width calc(100% - 30px)
    margin 21px 15px 23px
    font-size 14px
    height 44px
</style>
